/**
 * 
 * Middleware: AWS Amplify authentikáció (Cogino & IAM)
 * 
 * A middleware azokat az oldalakat védi, amelyekhez bejelentkezés szükséges.
 * Ennek hiányában:
 *  - a védett oldalakon a GraphQL kérések "permission error" hibát dobnának
 *  - és nem férünk hozzá a user adataihoz
 * 
 * !FONTOS!
 *  - Az Amplify Auth configban cookie-t használjunk, más nem érhető el SSR esetén a middleware-ben!
 * 
 *  - A főoldalba ('/') NE helyezzük el az auth middleware-t, mert infinite loop-hoz vezet.
 *    Ha valamilyen oknál fogva mégis kell (pl dashboard védelme), akkor a '/'-re való átirányítást
 *    felül kell vizsgálni alább.
 * 
*/

import { storeToRefs } from 'pinia';
import { useAuthStore } from "~/store/auth";

// export default defineNuxtRouteMiddleware(async (to, from) => {
export default defineNuxtRouteMiddleware((to, from) => {

  const { authenticated } = storeToRefs(useAuthStore());
  // console.log('middleware: authenticated: ', authenticated.value);
  // console.log('from: ', from.fullPath);

  // if (process.server) {
  //   return;
  // }
  
  // if (process.client) {
  //   return;
  // }

  const nuxt = useNuxtApp();
  // console.log('middleware called');

  // ha a user nincs bejelentkezve
  if (authenticated.value !== true) {

    // checkout oldal
    if (to.fullPath.startsWith(nuxt.$localePath('/checkout'))) {
      if(to.query.step === 'firststepemail' || to.query.step === 'login') {
        return;
      } else {
        // minden egyéb /checkout aloldal esetén, ha nincs bejelentkezve, irány a login
        //  (nem a firststepemail mert azon a feluleten mar tovabblepett, ergo nem uj reg)
        return navigateTo(nuxt.$localePath({ path: '/checkout', query: { step: 'login' }}));
      }
    }

    // Ha a user a login oldalon van és nincs bejelentkezve:
    //  akkor nem csinálunk semmilyen átirányítást
    if(to.fullPath === nuxt.$localePath('/login')) {
      return;
    }
    // Ha a user NEM a login oldalon van és nincs bejelentkezve:
    //  átirányítjuk a bejelentkezés oldalra:
    if(to.fullPath !== nuxt.$localePath('/login')) {
      return navigateTo(nuxt.$localePath('/login'));
    }
  }
  if (authenticated.value === true) {
    // Ha a user a login oldalon van, DE már be van jelentkezve:
    if(to.fullPath === nuxt.$localePath('/login')) {
      return navigateTo(nuxt.$localePath('/'));
      // return navigateTo(from.fullPath); // megvizsgálandó: jó-e ha visszaküldöm?
    }
  }

  // HA van regisztráció oldal, akkor ezt be kell kapcsolni:
  // Ha a user a login oldalon van, DE már be van jelentkezve:
  // if(to.fullPath === nuxt.$localePath('/registration')) {
  //   return navigateTo(nuxt.$localePath('/'));
  // }

});
